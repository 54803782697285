<template>
  <v-chip
    v-on:click="pushToDetail"
    class="mt-2 mb-2 font-weight-600 custom-barcode"
    :class="{ 'cursor-pointer': id, 'cursor-default': !id }"
    color="chip-custom-blue"
    outlined
    label
  >
    {{ barcode }}
  </v-chip>
</template>

<script>
export default {
  props: {
    barcode: {
      type: String,
      required: true
    },
    route: {
      type: String
    },
    id: {
      type: Number
    }
  },
  methods: {
    pushToDetail() {
      let detailID = this.lodash.toSafeInteger(this.id);
      if (this.route && detailID > 0) {
        this.$router.push(
          this.getDefaultRoute(this.route, {
            params: { id: detailID }
          })
        );
      }
    }
  }
};
</script>
